.btn-raised,
.btn-stroked {
  display: inline-block;
  line-height: 1.5;
  text-decoration: none;
  transition: 0.3s;
}
.btn-raised,
.btn-stroked,
.text-btn {
  font-size: 1rem;
  font-weight: 400;
}
:root {
  --primary-color: #7656d8;
  --dark-color: #14151f;
  --grey-color: #6a6b71;
  --blue-color: #3179f9;
  --green-color: #6dc065;
  --white-color: #ffffff;
  --bg-color: #efefef;
  --black-color: #000;
  --red-color: #d23c33;
  --Light-Blue---Primary-400: #4285f4;
  --Gray-800: #14151f;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
  font-family: UKNumberPlate;
  src: local('UKNumberPlate'), url(./assets/fonts/UKNumberPlate.ttf), format('truetype');
  font-display: swap;
}
@font-face {
  font-family: CourierPrime-Regular;
  src: local('CourierPrime-Regular'), url(./assets/fonts/CourierPrime-Regular.ttf), format('truetype');
}
.btn-raised {
  border: none;
  border-radius: 6px;
  padding: 11px 20px;
  background: #9557e8;
  color: #fff;
}
.btn-raised:hover {
  box-shadow: 0 0 7px 1px #9557e8;
  color: #fff;
}
.btn-raised.small,
.btn-transparent.small {
  padding: 8px 12px;
  font-size: 14px;
}
.btn-raised.green {
  background: var(--green-color);
}
.btn-stroked,
.btn-stroked.transparent,
.text-btn {
  background: 0 0;
}
.btn-raised.green:hover {
  box-shadow: 0 0 7px 1px var(--green-color);
}
.btn-stroked {
  border: 2px solid #9557e8;
  border-radius: 6px;
  padding: 9px 20px;
  color: #9557e8;
}
.btn-icon,
.text-btn {
  color: #9557e8;
  padding: 0;
}
.btn-stroked:hover {
  background-color: #9557e8;
  color: #fff;
}
.btn-icon {
  min-width: 36px;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.text-btn {
  border: none;
  line-height: 1;
}
.btn-link,
.btn-transparent {
  display: inline-flex;
  gap: 8px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  background: 0 0;
}
.btn-transparent {
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 2px;
  color: var(--Gray-800, #14151f);
  font-size: 18px;
  letter-spacing: -0.18px;
  transition: 0.3s;
}
.btn-link,
.btn-transparent.primary {
  color: var(--Light-Blue---Primary-400, #4285f4);
}
.btn-transparent.danger {
  color: var(--Red---Semantic-500, #c22222);
}
.btn-transparent.active,
.btn-transparent:hover {
  background: 0 0;
  color: var(--Gray-800, #14151f);
}
.btn-raised.round,
.btn-stroked.round {
  min-width: 140px;
  border-radius: 40px;
}
.btn-icon:disabled,
.btn-raised:disabled,
.btn-stroked:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.quote-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.8);
  border-radius: 15px;
  width: 100%;
  max-width: 550px;
  overflow: hidden;
  position: relative;
}
.btn-link {
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  letter-spacing: -0.16px;
  border: none;
}
.container,
.container-pl {
  padding-left: 1rem;
}
.btn-link.small {
  font-size: 14px;
  line-height: 20px;
}
.btn-link.xs {
  font-size: 12px;
  line-height: 18px;
}
.container {
  padding-right: 1rem;
}
.text-white {
  color: var(--white-color) !important;
}
.text-grey {
  color: var(--grey-color) !important;
}
.text-primary {
  color: var(--primary-color) !important;
}
.text-dark {
  color: var(--dark-color) !important;
}
.text-gray-400 {
  color: var(--Gray-400, #c1c1c3) !important;
}
.text-gray-600 {
  color: var(--Gray-600, #6a6b71) !important;
}
.text-gray-700 {
  color: var(--Gray-700, #474747) !important;
}
.text-base-700 {
  color: #4a5468 !important;
}
.text-base-800 {
  color: #2d3648 !important;
}
.text-green-600 {
  color: #138531 !important;
}
.text-red-500 {
  color: #c22222 !important;
}
.bg-dark {
  background: var(--dark-color) !important;
}
.bg-grey {
  background: var(--bg-color) !important;
}
.bg-primary {
  background: var(--primary-color) !important;
}
.flex-1 {
  flex: 1;
}
.drawer-rounded.MuiDrawer-modal .MuiPaper-elevation {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
@media (min-width: 576px) {
  .container-pl {
    padding-left: calc((100vw - 540px) / 2 + 1rem);
  }
}
@media (min-width: 768px) {
  .container-pl {
    padding-left: calc((100vw - 720px) / 2 + 1rem);
  }
}
@media (min-width: 992px) {
  .container-pl {
    padding-left: calc((100vw - 960px) / 2 + 1rem);
  }
}
@media (min-width: 1200px) {
  .container-pl {
    padding-left: calc((100vw - 1140px) / 2 + 1rem);
  }
}
@media (min-width: 1400px) {
  .container-pl {
    padding-left: calc((100vw - 1320px) / 2 + 1rem);
  }
}
