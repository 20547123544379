.pay-now,
area:hover {
  cursor: pointer;
}
.App {
  text-align: center;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submit-request-btn {
  z-index: 120;
  height: 60px;
}
.submit-btn-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 965px;
  height: 100vh;
  max-height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  overflow: hidden;
}
.toggle-van {
  margin-right: 10px;
  width: 133px;
  display: inline-block;
}
.tab {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 10px;
}
.tab-content {
  animation: 1s fadeIn;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.submit-request-msg {
  font-size: large;
  color: #ff3232;
  margin-bottom: 15px;
}
.form-not-filled {
  border: 1.5px solid red;
}
.form-text-error {
  color: #d32f2f;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 400;
}
.empty-test {
  font-family: UKNumberPlate;
  visibility: hidden;
  height: 1px;
  font-display: swap;
}
.transparent-element {
  display: none;
}
.pay-now {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  background: #0a2540;
  align-items: center;
  position: relative;
}
.pay-now .pay-text {
  margin: auto;
}
.pay-now .pay-icon {
  position: absolute;
  right: 10px;
}
.pay-now.invalid .pay-text {
  color: #ffffff99;
}
.pay-now:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.text-left {
  text-align: left;
}
.terms {
  font-size: 14px;
}
.terms a {
  color: #484848;
}
.pay-success {
  fill: #2ab784;
  border: 2px solid #2ab784;
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
}
.success-msg {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 16px;
  padding-top: 24px;
}
